/** @jsx jsx */
import { jsx } from "@emotion/react";
import { graphql } from "gatsby";
import Seo from "gatsby-plugin-wpgraphql-seo";
import styled from "@emotion/styled";
import React from "react";
import { Query } from "../../gatsby-types";

import AboutIntro from "../components/AboutIntro";
import AboutUs from "../components/AboutUs";
import Hero from "../components/Hero";
import Layout from "../components/Layout";
import SectionGrey from "../components/SectionGrey";

import { media, Breakpoint } from "../theme";

const SectionGreyNoPadding = styled(SectionGrey)`
  padding: 0;

  ${media(Breakpoint.Tablet)} {
    padding: 0;
  }
`;

type PropsType = {
  data: Query;
};

type acfAboutProps = {
  hero: string;
  heroSubheader: string;
  contentText: string;
  onTheImageHeader: string;
  onTheImageText: string;
};

const About: React.FC<PropsType> = (props) => {
  if (!props.data.wpPage || !props.data.wpPage.acfAbout) {
    return null;
  }

  const { hero, heroSubheader, contentText, onTheImageHeader, onTheImageText } = props.data.wpPage
    .acfAbout as acfAboutProps;

  return (
    <Layout>
      <Seo post={props.data.wpPage} />
      <Hero page="about" header={hero} paragraph={heroSubheader} />
      <SectionGreyNoPadding>
        <AboutIntro css={{ margin: "0 auto" }} content={contentText} />
        <AboutUs header={onTheImageHeader} content={onTheImageText} />
      </SectionGreyNoPadding>
    </Layout>
  );
};

export const pageQuery = graphql`
  query AboutPage($wpId: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    wpPage(id: { eq: $wpId }) {
      id
      title
      nodeType
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      acfAbout {
        hero
        heroSubheader
        contentText
        onTheImageHeader
        onTheImageText
      }
    }
  }
`;

export default About;
