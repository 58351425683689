/** @jsx jsx */
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import TextContainer from "../Content/TextContainer";

import { media, Breakpoint } from "../../theme";

const Icon = styled.img`
  display: block;
  margin: 0 auto 40px;
`;

const AboutIntroText = styled(TextContainer)`
  p {
    font-size: 18px;
    line-height: 34px;
    text-align: center;
  }
`;

const AboutIntroInner = styled.div``;

interface AboutIntroProps {
  className?: string;
  content: string;
}

const AboutIntro: React.FC<AboutIntroProps> = ({ className, content }) => {
  return (
    <div className={className}>
      <Icon alt="logo" src={`/about-intro.svg`} width={100} height={31} />
      <AboutIntroInner>
        <AboutIntroText dangerouslySetInnerHTML={{ __html: content }} />
      </AboutIntroInner>
    </div>
  );
};

export default styled(AboutIntro)`
  position: relative;
  padding: 40px 20px;
  overflow: hidden;
  max-width: 705px;

  ${media(Breakpoint.Tablet)} {
    padding: 100px 20px;
  }
`;
